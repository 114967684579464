import React from 'react'
import saeLogo from '../../images/enlaces/sae_logo.svg'
import gmailLogo from '../../images/enlaces/gmail.svg'
import papinotas from '../../images/enlaces/papinotas.svg'
import lirmi from '../../images/enlaces/lirmi.svg'
import facebook from '../../images/enlaces/facebook.svg'
import youtube from '../../images/enlaces/youtube.svg'
import inasistencia from '../../images/enlaces/logo.png'
import { Row } from 'react-bootstrap'
import { Enlace } from './enlace'

export const Enlaces = () => {
  const data = [
    {id: '1', name: 'S. Admision Escolar', icon: saeLogo, ref:'https://www.sistemadeadmisionescolar.cl/'},
    {id: '2', name: 'Correo Institucion', icon: gmailLogo, ref:'https://mail.google.com/a/colegiocielosdelvalle.cl'},
    {id: '3', name: 'Lirmi', icon: lirmi, ref:'https://login.lirmi.com/login'},
    {id: '4', name: 'Youtube oficial', icon: youtube, ref:'https://www.youtube.com/channel/UCqSeDAvpzXNB-TG9zQA6F9w'},
    {id: '5', name: 'Facebook oficial', icon: facebook, ref:'https://www.facebook.com/Establecimiento-Cielos-Del-Valle-La-Serena-106124776659910'},
    {id: '6', name: 'Formulario inasistencia', icon: inasistencia, ref:'http://appinasistencia.colegiocielosdelvalle.cl'},
  ]


  return (
    <>
      <h3>Sitios de interes</h3>
      <Row className="mt-3 justify-content-md-center">
        { data && data.map(enlace => (
          <Enlace 
            enlace={enlace}
            key={enlace.id}
          />
        ))}
        </Row>
    </>
  )
}
